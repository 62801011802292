/* SETTINGS */
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Source+Sans+Pro:wght@400;700&display=swap');

/* GENERIC */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Source Sans Pro, monospace;
  font-size: 20px;
  line-height: normal;
}

:root{
  --primary: #151d3a;
  --background: #0d142d;
  --color: white;
  --color-red: #ec1840;
  --color-purple: #7a18ec;
  --color-white: #fff;
  --color-black-1: #111;
  --color-black-2: #222;
  --color-black-3: #444;
  --speed-normal: 0.5s;
  --speed-fast: 0.8s;
  --color-var-1: #7FBA27;
  --color-var-2: #6AC2BA;
  --color-var-3: #E62D28;
  --color-var-4: #344B9A;
  --color-var-5: #E72585;
}

/* BASE ELEMENTS */
body{
  line-height: 1.8;
  font-weight: normal;
  background-color: var(--background);
  color: var(--color);
  text-align: left;
  min-height: 100vh;
  overflow-x: hidden;
}

#root{
  min-height: 100vh;
}

article{
  position: relative;
  margin-bottom: 8rem;
}

p, ul{
  margin-bottom: 16px
}

ul{
  padding-left: 1rem;
}

a{
  color: white;
  font-weight: bold;
  transition: color var(--speed-fast);
}

a:hover{
  color: var(--color-var-1)
}

/* OBJECT */
.wrapper {
  display: flex;
  width: 100%;
}

.col-2{
  flex: 0 1 45.4546%;
  max-width: 45.4546%;
  position: relative;
}

.col-2:last-of-type{
  margin-left: 9.09091%;
}

/* COMPONENTS */
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.menu-link{
  transition: color var(--speed-fast);
  cursor: pointer;
}


/* UTILITIES */
.active,
.menu-link:hover{
  color: var(--color-var-1) !important;
}

.d-flex{
  display: flex !important;
  flex-wrap: wrap;
}

.tilted-col{
  margin-top: 200px;
}

.pv-2{
  padding: 2rem 0 !important
}

.mv-2{
  margin: 4rem 0 !important;
}

.z-index-2{
  position: relative !important;
  z-index: 2 !important;
}